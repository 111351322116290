import {
  Button,
  Modal as CloudearModal,
  Typography,
} from '@collection-platform-frontend/ui';
import Link from 'next/link';
import { forwardRef, useCallback, useState } from 'react';

interface AgreementPolicyModalProps {
  onCallback: (value: boolean) => void;
}

export interface IRefAgreementPolicyModal {
  toggleModal: () => void;
}

export const CLOUDEAR_AGREEMENT_POLICY_STORAGE_KEY =
  'CLOUDEAR_AGREEMENT_POLICY_STORAGE_KEY';

export enum AgreementPolicyAgreed {
  AGREED = 'agreed',
  NOT_AGREED = 'not_agreed',
}

export const AgreementPolicyModal = forwardRef<
  IRefAgreementPolicyModal,
  AgreementPolicyModalProps
>(({ onCallback = () => null }, ref) => {
  const getAgreedStorage = () => {
    if (typeof window === 'undefined') {
      return AgreementPolicyAgreed.NOT_AGREED;
    }
    return (
      window?.localStorage?.getItem(CLOUDEAR_AGREEMENT_POLICY_STORAGE_KEY) ??
      AgreementPolicyAgreed.NOT_AGREED
    );
  };
  const setAgreedStorage = (value: AgreementPolicyAgreed) => {
    if (typeof window === 'undefined') {
      return;
    }
    window?.localStorage?.setItem(CLOUDEAR_AGREEMENT_POLICY_STORAGE_KEY, value);
  };

  const [isDisplay, setIsDisplay] = useState(
    getAgreedStorage() === AgreementPolicyAgreed.NOT_AGREED,
  );

  const agreePolicy = useCallback(() => {
    setAgreedStorage(AgreementPolicyAgreed.AGREED);
    setIsDisplay(false);
    onCallback(true);
  }, [setIsDisplay, onCallback]);

  const disagreePolicy = useCallback(() => {
    setAgreedStorage(AgreementPolicyAgreed.NOT_AGREED);
    setIsDisplay(true);
    onCallback(false);
  }, [setIsDisplay, onCallback]);

  return (
    <CloudearModal
      show={isDisplay}
      contentClassName="sm:max-w-lg h-full select-none"
    >
      <div className=" p-6 overflow-hidden text-center transition-all transform shadow-xl max-w-[550px] bg-wallet-light-base dark:bg-wallet-base text-wallet-light-primary dark:text-wallet-primary rounded-3xl">
        <div className="pb-4 text-center ">
          <Typography>
            閲覧にあたっては、
            <Link
              href="/policy"
              target="_blank"
              className="text-blue-700 underline"
            >
              CLOUDEARの利用規約
            </Link>
            への同意が必要となります
          </Typography>
        </div>
        <div className="flex justify-center items-center">
          <Button
            className="border-hidden w-fit focus-visible:!outline-none"
            slim
            onClick={agreePolicy}
          >
            同意する
          </Button>
          <Button
            className="border-hidden w-fit focus-visible:!outline-none"
            slim
            onClick={disagreePolicy}
          >
            同意しない
          </Button>
        </div>
      </div>
    </CloudearModal>
  );
});

AgreementPolicyModal.displayName = 'AgreementPolicyModal';

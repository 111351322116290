import {
  dateFilter,
  isARModelAsset,
  isModelAsset,
  useGetNftDetailQuery,
} from '@collection-platform-frontend/api';
import { useDarkModeContext } from '@collection-platform-frontend/shared';
import {
  Button,
  DarkModeToggle,
  DynamicModelViewer,
} from '@collection-platform-frontend/ui';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

const animationContractNames = ['SerialExperimentsLain'];

type PathString = `/${string}`;

type RenderOptions = {
  currentApplicationId?: string;
  parentPagePath?: PathString;
  useDarkModeSetting?: boolean;
};

export const PublicViewer3DPageRender = ({
  currentApplicationId,
  parentPagePath = '/collection',
  useDarkModeSetting,
}: RenderOptions = {}) => {
  const Viewer3DPage = () => {
    const { push, isReady, query } = useRouter();
    const id = query['id'] as string;

    const { isDarkMode, changeMode } = useDarkModeContext();

    const [{ data }] = useGetNftDetailQuery({
      pause: !isReady || !id,
      variables: {
        id,
        applicationId: currentApplicationId,
      },
      requestPolicy: 'network-only',
    });

    const nfts = data?.nfts.edges?.map(({ node }) => node);
    const currentNft = nfts?.[0];
    const publicContents = currentNft?.item?.publicContents;
    const isAutoplayContent = currentNft?.contractDisplayName
      ? animationContractNames.includes(currentNft.contractName)
      : false;

    const modelContent = publicContents
      ?.filter((content) => {
        return isModelAsset(content.publicAsset?.mimeType);
      })
      ?.sort(dateFilter)[0];

    const arModelContent = publicContents
      ?.filter((content) => {
        return isARModelAsset(content.publicAsset?.mimeType);
      })
      ?.sort(dateFilter)[0];

    const sourceUrl = modelContent?.publicAsset?.url as string;
    const backgroundUrl = modelContent?.backgroundPublicAsset?.url as string;
    const iosSrcUrl = arModelContent?.publicAsset?.url as string;

    const onBackToCollection = useCallback(() => {
      push(`${parentPagePath}/${id}`);
    }, [id, push]);

    const onToggleMode = useCallback(() => {
      changeMode(!isDarkMode);
    }, [changeMode, isDarkMode]);

    return (
      <div>
        <div className="fixed w-full h-screen">
          <DynamicModelViewer
            src={sourceUrl}
            iosSrc={iosSrcUrl}
            autoPlay={isAutoplayContent}
            backgroundImage={backgroundUrl as string}
          />
        </div>
        <Button
          primary={true}
          className="fixed w-8 h-8 p-2 bg-gray-500 rounded-full top-4 left-4 min-w-8"
          onClick={onBackToCollection}
        >
          <ChevronLeftIcon className="w-4 h-4" />
        </Button>
        {!backgroundUrl && useDarkModeSetting && (
          <div className="fixed bottom-4 left-4">
            <DarkModeToggle toggle={isDarkMode} onChange={onToggleMode} />
          </div>
        )}
      </div>
    );
  };

  return Viewer3DPage;
};
